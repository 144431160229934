import {post,get} from './request.js'
import config from './config'
// 查询区块详情
export function GetBlockbyNum(params,loading=true){
	return get(config.url+'/block',params,loading)
	// return post('/api/GetBlockbyNum',params,loading)
	
}
//GetUSDkto
export function GetUSDkto(params,loading=true){
	//return get('/api/GetUSDkto',params,loading)
	return post('https://www.bcone.vip/api/market/tickers/ticker?symbol=usdt_kto',params,loading)
}
// minerData
export function minerData(params,loading=true){
	return get('https://www.kortho.io/website/minerData',params,loading)
}

// 通过地址查询所有交易
export function GetTxsbyAddr(params,loading=true){
	// return post('/api/GetTxsbyAddr',params,loading)
	return get(config.url1+'/GetTxsByAddr',params)
}
// 获取块高列表
export function GetBlockbyNum_FY(params){
	return get(config.url+'/block',params)
	// return post('/api/GetBlockbyNum_FY',params)
	
}
// 最新区块获取交易列表
export function GetBlock_FY(params){
	return get('/api/GetBlock_FY',params)
}
// 交易详情
export function GetTxbyhash(params){
	return get(config.url+'/transaction',params)
	// return post('/api/GetTxbyhash',params)
}
// 获取详细交易精度
export function GetTokenDemic(params){
	// return post('/api/GetTokenDemic',params)
	return get(config.url+'/token/demic',params)
	
}
// 首页查询最新区块
export function GetMaxBlockNum(params,loading=true){
	return get(config.url+'/height',params,loading)
	// return post('/api/GetMaxBlockNum',params,loading)
}
//获取首页统计数据
export function GetTotalData(params,loading=true){
	return get(config.url1+'/GetTotalData',params,loading)
}
// 查询价格
export function GetBalance(params,loading=true){
	return get(config.url+'/balance',params,loading)
	// return post('/api/GetMaxBlockNum',params,loading)
}
//defi
export function GetDeDi(params,loading=true){
	return post('https://www.bcone.vip/api/market/tickers/ticker?symbol=usdt_'+ params,params,loading)
}
//首页图表数据
export function Get24HData(params,loading=true){
	return get(config.url1+'/Get24HData',params,loading)
}
// 请求本地数据 
export function getSystemData(params,loading=true){
	return get('https://www.kortho.cc/static/data.js',params,loading)
}
export function getAllTokensApi(params,loading=true){
	return get(config.url1+'/GetAllTokens',params,loading)
}