<template>
	<div class="my-header">
		<div class="container head-box">
			<p class="head-box-logo" @click="goPage('/')">
				<img src="../assets/img/logo.png" />
				<span class="dnone">Kortho Chain</span>
			</p>
			<div class="nav">
				<a href="https://korthochain.github.io/chain-docs/" target="_blank">Developer</a>
				<span @click="goPage('/wallet')" :class="{'active':tabIndex=='wallet'}">Wallet</span>
				<span @click="goPage('/apps')" :class="{'active':tabIndex=='apps'}">Apps</span>
				<a href="https://www.kortho.io/" target="_blank">Browser</a>
				<span @click="goPage('/system')" :class="{'active':tabIndex=='system'}">Info</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				showMenu:false,
				tabIndex:'home'
			}
		},
		watch:{
			$route(to,from){
				if(to.name=='detail'){
					this.tabIndex = 'system'
				}else{
					this.tabIndex = to.name
				}
			}
		},
		methods:{
			goPage(url){
				this.$router.push({
					path:url
				})
			},
			goBrower(){
				window.loc = ''
			}
		}
	}
</script>

<style scoped lang="scss">
	.my-header{
		background: #ffffff;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100px;
		user-select: none;
		z-index: 1000;
		.phone-menu{
			display: none;
		}
		.head-box-logo{
			display: flex;
			align-items: center;
			span{
				font-size: 30px;
				margin-left: 10px;
				font-weight: bolder;
			}
		}
		.head-box{
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.nav{
			span{
				margin-left: 50px;
				font-size: 16px;
				cursor: pointer;
			}
			a{
				margin-left: 50px;
				font-size: 16px;
				cursor: pointer;
			}
			.active{
				font-weight: bolder;
			}
		}
	}
</style>
