<template>
	<div class="footer">
		<div class="container footer-main">
			<div class="footer-item">
				<h1>About Kortho Chain</h1>
				<a @click="goPage('/system')">System announcement</a>
				<a @click="goPage('/wallet')">Wallet</a>
				<a href="https://www.kortho.io/" target="_blank">Browser</a>
			</div>
			<div class="footer-item">
				<h1>Development Tool</h1>
				<a href="https://www.kortho.org/file/kortho.pdf" target="_blank">White paper</a>
				<a href="https://korthochain.github.io/chain-docs/" target="_blank">Developer</a>
			</div>
			<div class="footer-item">
				<h1>Our Certificate</h1>
				<a target="_blank" href="https://centerk.oss-cn-hongkong.aliyuncs.com/KORTHO%20Blockchain%20Security%20Audit%20Report%20%20.pdf" data="代码安全审计">Security Audit Report</a>
				<a target="_blank" href="https://centerk.oss-cn-hongkong.aliyuncs.com/Kortho%20Chain%20-%20Legal%20opinion%20%28211115_clean%29%28BFL%29.pdf" data="法律文书">Legal opinion</a>
				<a target="_blank" href="https://centerk.oss-cn-hongkong.aliyuncs.com/AUTH%20KORTHO%20TECHNOLOGY%20LABORATORY%20FOUNDAION.pdf" data="基金会证书">Technology laboratory foundaion</a>
				<a target="_blank" href="https://centerk.oss-cn-hongkong.aliyuncs.com/KORTHO%20TECHNOLOGY%20LABORATORY%20FOUNDAION.pdf" data="MSB金融牌照">MSB Registration Letter</a>
			</div>
			<div class="footer-right">
				<h1>Download</h1>
				<a href="javascript:;">Wallet</a>
				<img src="../assets/img/dwn.png" >
				<!-- <h1>Contact us</h1> -->
				<a href="javascript:;">Mail：korthochain@gmail.com</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {}
		},
		methods:{
			goPage(url){
				this.$router.push({path:url})
			}
		}
	}
</script>

<style scoped lang="scss">
	.footer-main{
		display: flex;
		justify-content: space-between;
		padding: 5rem 0 2rem;
		h1{
			font-size: 16px;
			color: #195DF2;
			padding-bottom: 1.25rem;
		}
		.footer-item{
			width: 25%;
			display: flex;
			flex-direction: column;
			a{
				padding: 0.3rem 0;
				font-size: 1rem;
			}
		}
		.footer-right{
			width: 20%;
			display: flex;
			flex-direction: column;
			a{
				padding: 0.3rem 0;
				font-size: 1rem;
			}
			img{
				width: 90px;
				height: 90px;
			}
		}
	}
</style>
