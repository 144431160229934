<template>
	<div class="my-app">
		<!-- 头部 -->
		<headerComponent></headerComponent>
		<div class="app-content">
			<router-view/>
			<footerComponent></footerComponent>
		</div>
	</div>
</template>

<script>
	import headerComponent from './views/header.vue'
	import footerComponent from './views/footer.vue'
	export default {
		components:{headerComponent,footerComponent},
		data(){
			return {}
		},
	}
</script>

<style lang="scss" scoped>
	.my-app{
		padding-top: 100px;
	}
</style>
