import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('../views/wallet.vue')
  },
  {
    path: '/apps',
    name: 'apps',
    component: () => import('../views/apps.vue')
  },
  {
    path: '/miner',
    redirect:'/wallet'
  },
  {
    path: '/system',
    name: 'system',
    component: () => import('../views/system.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/sysDetail.vue')
  }
]

const router = createRouter({
	// history: createWebHistory(process.env.BASE_URL),
	history: createWebHashHistory(process.env.BASE_URL),//修改后
	routes
})

export default router
