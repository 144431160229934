<template>
	<div class="home">
		<!-- banner -->
		<div class="banner">
			<div class="banner-main container">
				<p class="banner-title">Welcome to Kortho Chain</p>
				<p class="banner-txt">The world's leading bottom public chain of data right confirmation</p>
				<p class="banner-txt">Cotu chain can help you build your own data application ecology faster and more safely</p>
				<!-- <p class="connect-wallet">Connect Wallet</p> -->
			</div>
		</div>
		<!-- Our Advantages -->
		<div class="container advantages">
			<h1>Our Advantages</h1>
			<p class="adv-title-txt">Start a new journey of digital art collection</p>
			<div class="adv-main">
				<div class="adv-item">
					<img src="../assets/img/home-p5.png" />
					<div class="adv-info">
						<p class="adv-info-title">High Performance</p>
						<p class="adv-info-txt">More than 100000 TPS per second,which can quickly build applications</p>
					</div>
				</div>
				<div class="adv-item">
					<img src="../assets/img/home-p4.png" />
					<div class="adv-info">
						<p class="adv-info-title">Low Handling Charge</p>
						<p class="adv-info-txt">Democratic autonomous community a new ecology of shared data economy which is benefited by multiple participation and multi benefits</p>
					</div>
				</div>
				<div class="adv-item">
					<img src="../assets/img/home-p3.png" />
					<div class="adv-info">
						<p class="adv-info-title">Open Community</p>
						<p class="adv-info-txt">The service charge of each transaction can be as low as US $0.0001 making the transaction more convenient</p>
					</div>
				</div>
			</div>
		</div>
		<!-- Start Experiencing -->
		<div class="container exper">
			<h1>Start Experiencing</h1>
			<div class="exper-box">
				<div class="exper-item exper-img">
					<img src="../assets/img/home-p2.png" >
				</div>
				<div class="exper-item exper-arrow exper-left">
					<img src="../assets/img/home-left.png" >
					<h2>Developer <br/> Documentation</h2>
					<p>Reading the developer documentation will be of</p>
					<p>great help for you to join the Kortho Chain</p>
				</div>
				<div class="exper-item exper-arrow exper-right">
					<img src="../assets/img/home-right.png"/>
					<h2>Select Wallet</h2>
					<p>You can link Kortho Chainand manage assets
by selecting wallet</p>
				</div>
				<div class="exper-item exper-img">
					<img src="../assets/img/home-p1.png"/>
				</div>
				<div class="exper-item exper-img">
					<img src="../assets/img/market.png" >
				</div>
				<div class="exper-item exper-arrow exper-left">
					<img src="../assets/img/home-left.png" >
					<h2>Dapp Market</h2>
					<p>Contains a variety of applications which will greatly</p>
					<p>enhance your use experience in Kortho Chain</p>
				</div>
			</div>
		</div>
		<!-- Kortho Chain Present Situation -->
		<div class="container present">
			<h1>Kortho Chain Present Situation</h1>
			<p class="pre-txt">500 Days of Website Operation</p>
			<div class="pre-main">
				<div class="pre-item">
					<img src="../assets/img/home-shuju.png" />
					<div class="pre-item-txt">
						<p>{{block}}</p>
						<p>Block Height</p>
					</div>
				</div>
				<div class="pre-item">
					<img src="../assets/img/home-shuju.png" />
					<div class="pre-item-txt">
						<p>{{interval}} s</p>
						<p>Block interval</p>
					</div>
				</div>
				<div class="pre-item">
					<img src="../assets/img/home-shuju.png" />
					<div class="pre-item-txt">
						<p>{{Circulation}}</p>
						<p>Total Circulation</p>
					</div>
				</div>
				<div class="pre-item">
					<img src="../assets/img/home-shuju.png" />
					<div class="pre-item-txt">
						<p>{{txn}}</p>
						<p>Total Transactions</p>
					</div>
				</div>
				<div class="pre-item">
					<img src="../assets/img/home-shuju.png" />
					<div class="pre-item-txt">
						<p>{{totalamount}}</p>
						<p>Transaction Amount</p>
					</div>
				</div>
				<div class="pre-item">
					<img src="../assets/img/home-shuju.png" />
					<div class="pre-item-txt">
						<p>{{accounts}}</p>
						<p>Total Accounts</p>
					</div>
				</div>
			</div>
		</div>
		<!-- Building an open ecological chain -->
		<div class="ecol container">
			<p>Building an open ecological chain</p>
			<a href="https://twitter.com/kortho2" target="_blank">
				<img src="../assets/img/twe.png" />
			</a>
			<a href="https://t.me/KorthoChain" target="_blank">
				<img src="../assets/img/chanel.png" />
			</a>
			
		</div>
		<!-- Cooperative Partner -->
		<div class="partner">
			<div class="container partner-box">
				<p>Cooperative Partner </p>
				<img src="../assets/img/home-caijing.png" >
			</div>
		</div>
	</div>
</template>

<script>
	import {GetMaxBlockNum,GetTotalData,minerData} from '@/utils/api.js'
	export default {
			data(){
				return {
					block:'--',
					accounts:'--',
					txn:'--',
					marketValue:'--',
					totalamount:'--',
					interval:'--',
					Circulation:'--'
				}
			},
			mounted() {
				//this.getMaxBlockNum()
				this.getTotalData()
			},
			methods:{
				getMaxBlockNum(){
					GetMaxBlockNum({}).then(res=>{
						if(res.code==0){
							this.block = res.data
						}
						
					})
				},
				getTotalData(){
					var that = this
					GetTotalData({}).then(res=>{
						if(res.code==0){
							this.accounts=res.activeaddr
							this.totalamount = res.totalamount
							this.txn=res.totaltxs
							// this.marketValue=0
							// GetUSDkto({}).then(p=>{
							// 	if(p.success){
							// 		that.marketValue = (parseFloat(p.data.Last)*88480000).toFixed(2)
							// 	}
							// })
						}
					})
					minerData({}).then(res=>{
						if(res.errCode==0){
							this.block=res.data.Height
							this.interval = res.data.AveTime
							this.Circulation = res.data.Circulation
						}
					})
				}
			},
			filters:{
				numformat (num) {
				    return (num).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g,'$1,');
				}
			}
		}
</script>
<style lang="scss" scoped>
	.banner{
		width: 100%;
		background: url(../assets/img/home-banner.png) no-repeat center;
		.banner-main{
			height: 580px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.banner-title{
				color: #ffffff;
				font-size: 60px;
				margin-bottom: 60px;
				line-height: 1;
			}
			.banner-txt{
				font-size: 20px;
				color: #ffffff;
			}
			.connect-wallet{
				width: 460px;
				height: 70px;
				background: #FFFFFF;
				border-radius: 35px;
				text-align: center;
				line-height: 70px;
				font-size: 24px;
				cursor: pointer;
				margin-top: 60px;
				user-select: none;
			}
		}
	}
	.advantages{
		padding: 6.25rem 0;
		h1{
			font-size: 3.75rem;
			line-height: 1;
			font-weight: normal;
		}
		.adv-title-txt{
			font-size: 1.5rem;
			padding-bottom: 3.125rem;
		}
		.adv-main{
			display: flex;
			justify-content: space-between;
			.adv-item{
				width: 24.75rem;
				position: relative;
				&:hover{
					transform: scale(1.02);
				}
				img{
					width: 100%;
				}
				.adv-info{
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					box-sizing: border-box;
					height: 50%;
					padding: 0 1.875rem;
					text-align: center;
				}
				.adv-info-title{
					font-size: 1.75rem;
					padding-bottom: 1.25rem;
				}
				.adv-info-txt{
					font-size: 1.125rem;
					opacity: 0.7;
				}
			}
		}
	}
	.exper{
		padding: 4.25rem 0;
		h1{
			font-size: 3.75rem;
			font-weight: normal;
			padding-bottom: 3.75rem;
		}
		.exper-box{
			display: flex;
			flex-wrap: wrap;
			.exper-item{
				width: 50%;
				h2{
					font-size: 2.5rem;
					line-height: 1;
					margin-bottom: 2.5rem;
					margin-top: 2.5rem;
				}
				p{
					font-size: 1.25rem;
				}
			}
			.exper-img{
				img{
					width: 100%;
					display: block;
				}
			}
			.exper-arrow{
				background: #FBFBFB;
			}
			.exper-left{
				text-align: right;
				padding: 0 2.375rem 2.375rem;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				box-sizing: border-box;
				align-items: flex-end;
				img{
					width: 63px;
					height: 68px;
				}
			}
			.exper-right{
				padding: 0 2.375rem 2.375rem;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				box-sizing: border-box;
				// align-items: flex-end;
				img{
					width: 63px;
					height: 68px;
				}
			}
		}
	}
	.present{
		padding: 4.25rem 0;
		h1{
			font-size: 3.75rem;
			line-height: 1;
			font-weight: normal;
		}
		.pre-txt{
			font-size: 1.5rem;
			padding-bottom: 3.125rem;
		}
		.pre-main{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.pre-item{
				width: 32%;
				position: relative;
				img{
					width: 100%;
				}
				.pre-item-txt{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					box-sizing: border-box;
					text-align: center;
					padding-top: 2.375rem;
					p{
						&:nth-child(1){
							font-size: 2.5rem;
							color: #195DF2;
						}
						&:nth-child(2){
							font-size: 1.5rem;
						}
					}
				}
			}
		}
	}
	.ecol{
		text-align: center;
		padding: 2.5rem 0 3.25rem;
		p{
			font-size: 3.75rem;
			padding-bottom: 3.75rem;
		}
		img{
			margin-bottom: 1.875rem;
		}
	}
	.partner{
		background: #F8FAFF;
		padding: 6.25rem 0;
		.partner-box{
			text-align: center;
			p{
				font-size: 3.75rem;
				padding-bottom: 3.75rem;
				line-height: 1;
			}
		}
	}
</style>